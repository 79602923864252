import React from 'react';

const UpdatedDocViewer = ({ src }) => (
	// eslint-disable-next-line jsx-a11y/iframe-has-title
	<iframe
		style={{ width: '100%', height: '90%', margin: '40px -15px 100px' }}
		src={src}
		frameBorder="0"
	/>
);

export default UpdatedDocViewer;
